<template>
  <div
    v-if="!loading && !exception && policyModalOpen"
    class="modal-overlay">
    <CookieConsentModal
      :title="content.fields.policyModalTitle"
      :text="content.fields.policyModalText"
      :show-save-button="false"
      @close="closePolicyModal()" />
  </div>
</template>

<script>
import CookieConsentModal from '@/components/cookie-consent/CookieConsentModal'
import {
  mapActions, mapState
} from 'vuex'

export default {
  name: 'CookiePolicyModalWrapper',
  components: { CookieConsentModal },
  computed: {
    ...mapState('CookieConsentContent', [
      'loading',
      'exception',
      'content'
    ]),
    ...mapState('CookieConsent', ['policyModalOpen'])
  },
  methods: {
    ...mapActions('CookieConsentContent', ['loadContent']),
    ...mapActions('CookieConsent', ['closePolicyModal'])
  }
}
</script>

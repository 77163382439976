<template>
  <div
    class="checkbox-input">
    <input
      :id="id"
      v-model="model"
      :value="checkedValue"
      :disabled="disabled"
      class="checkbox-input__checkmark"
      type="checkbox">

    <label
      :for="id"
      class="checkbox-input__label">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    checkedValue: {
      type: [
        String,
        Number,
        Boolean
      ],
      required: true
    },
    value: {
      type: [
        Array,
        String,
        Number,
        Boolean
      ],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.checkbox-input {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    display: inline-block;
    position: relative;
    appearance: none;

    background-color: transparent;
    border: 1px solid $core-black;
    border-radius: 2px;

    width: 20px;
    height: 20px;

    cursor: pointer;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;

    &:disabled, &:checked:disabled {
      background-color: $grey-300;
      border: 1px solid $grey-300;
    }

    &:checked {
      background-color: $core-black;
      transition: all 0.2s ease-in-out;
    }

    &:checked:after {
      content: url(../../assets/svg/check_mark.svg);
      font-size: 14px;
      position: absolute;
      left: 1px;
      color: $core-white;
    }
  }

  &__label {
    font-family: "Roboto", Arial, sans-serif;
    font-weight: 400;
  }
}
</style>

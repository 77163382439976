import {
  SORTING_KEY, sortingMap
} from '@/utils/offers.utils'
import NetAdminService from '@/services/net-admin.service.js'
import { PLATFORM_FILTER_KEY } from '@/utils/offers.utils'
import {
  setOfferList,
  getOfferList,
  getSubscriptionsList,
  setSubscriptionList,
  clearOffers,
  clearSubscriptions
} from '@/services/local-storage.service'

export default {
  namespaced: true,
  state: {
    exception: false,
    subscriptionsException: false,
    loadingOffers: false,
    loadingSubscriptions: false,
    connectedServices: [],
    offers: [],
    selectedOffer: null,
    sortBy: SORTING_KEY.BANDWIDTH_SPEED_HIGH_LOW,
    filters: {
      platform: [],
      bandwidthSpeed: [],
      provider: []
    },
    pagination: {
      limit: 20,
      offset: 0
    }
  },
  mutations: {
    setLoadingOffers (state, value) {
      state.loadingOffers = value
    },
    setException (state, value) {
      state.exception = value
    },
    setSubscriptionsException (state, value) {
      state.subscriptionsException = value
    },
    setLoadingSubscriptions (state, value) {
      state.loadingSubscriptions = value
    },
    setOffers (state, value) {
      state.offers = value
      state.offers.sort(sortingMap[state.sortBy])
      setOfferList(state.offers)
    },
    setConnectedServices (state, value) {
      state.connectedServices = value
      setSubscriptionList(state.connectedServices)
    },
    setSortBy (state, value) {
      state.sortBy = value
    },
    setFilters (state, value) {
      state.filters.platform = value.platform
      state.filters.bandwidthSpeed = value.bandwidthSpeed
      state.filters.provider = value.provider
    },
    setSelectedOffer (state, val) {
      state.selectedOffer = val
    },
    setPaginationOffset (state, val) {
      state.pagination.offset = val
    }
  },
  getters: {
    offers: (state) => {
      const offers = state.offers.length ? state.offers : getOfferList()

      return offers.sort(sortingMap[state.sortBy])
    },
    connectedServices: (state) => {
      return state.connectedServices.length ? state.connectedServices : getSubscriptionsList()
    },
    mappedOffers: (state, getters) => {
      return getters.offers.map((offer) => {
        offer.filters = []

        if (offer.serviceId > 0) {
          offer.filters.push(PLATFORM_FILTER_KEY.BREDBAND)
        }
        if (offer[PLATFORM_FILTER_KEY.TV]) {
          offer.filters.push(PLATFORM_FILTER_KEY.TV)
        }
        if (offer[PLATFORM_FILTER_KEY.TELEFONI]) {
          offer.filters.push(PLATFORM_FILTER_KEY.TELEFONI)
        }

        return offer
      })
    },
    allFilteredOffers: (state, getters) => {
      if (getters.mappedOffers.length) {
        const { filters } = state

        const platformFilter = offer => {
          return !filters.platform.length
              || filters.platform.every(filter => offer.filters.includes(filter))
              && filters.platform.length === offer.filters.length
        }

        const bandwidthSpeedFilter = offer => {
          return !filters.bandwidthSpeed.length
              || filters.bandwidthSpeed.includes(offer.downloadSpeedMBits)
        }

        const providerFilter = offer => !filters.provider.length || filters.provider.includes(offer.serviceProvider.name)

        return getters.mappedOffers
          .filter(platformFilter)
          .filter(providerFilter)
          .filter(bandwidthSpeedFilter)
      }
      return []
    },
    paginatedFilteredOffers: (state, getters) => {
      const { pagination: { offset, limit } } = state

      return getters.allFilteredOffers.slice(offset, offset + limit)
    }
  },
  actions: {
    async searchOffersPublic ({ commit }, payload) {
      try {
        clearOffers()

        commit('setOffers', [])
        commit('setException', false)
        commit('setLoadingOffers', true)
        const fetchOffersResponse = await NetAdminService.fetchOffersByPunktId(payload)

        commit('setOffers', fetchOffersResponse)

      } catch (err) {
        commit('setException', true)
      } finally {
        commit('setLoadingOffers', false)
      }
    },
    async searchOffersClosed ({ commit }, payload) {
      try {
        clearOffers()

        commit('setOffers', [])
        commit('setException', false)
        commit('setLoadingOffers', true)
        const fetchOffersResponse = await NetAdminService.fetchOffersByAddressId(payload)

        commit('setOffers', fetchOffersResponse)
      } catch (err) {
        commit('setException', true)
      } finally {
        commit('setLoadingOffers', false)
      }
    },
    async searchSubscriptions ({ commit }, payload) {
      try {
        clearSubscriptions()

        commit('setConnectedServices', [])
        commit('setSubscriptionsException', false)
        commit('setLoadingSubscriptions', true)
        const fetchSubscriptionsResponse = await NetAdminService.fetchSubscriptions(payload)

        commit('setConnectedServices', fetchSubscriptionsResponse)

      } catch (err) {
        commit('setSubscriptionsException', true)
      } finally {
        commit('setLoadingSubscriptions', false)
      }
    },
    clearFilters ({ commit }) {
      commit('setFilters', {
        platform: [],
        bandwidthSpeed: [],
        provider: []
      })
    },
    changeSorting ({ commit }, sortBy) {
      commit('setSortBy', sortBy)
    },
    resetSorting ({ commit }) {
      commit('setSortBy', SORTING_KEY.BANDWIDTH_SPEED_HIGH_LOW)
    },
    updateFilters ({ commit }, val) {
      commit('setFilters', val)
    },
    setOffers ({ commit }, val) {
      commit('setOffers', val)
    },
    setConnectedServices ({ commit }, val) {
      commit('setConnectedServices', val)
    },
    setSelectedOffer ({ commit }, val) {
      commit('setSelectedOffer', val)
    },
    setPaginationOffset ({ commit }, val) {
      commit('setPaginationOffset', val)
    }
  }
}

<template>
  <div
    :class="`spinner-${size}-${type}`"
    class="spinner" />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'primary'
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

$colors: (
  'primary-1': $core-teal-500,
  'primary-2': $core-teal-100,
  'secondary-1': $core-white,
  'secondary-2': $core-teal-100
);

@mixin type($size, $thiccness, $type) {
  height: #{$size};
  width: #{$size};
  border-top: #{$thiccness} solid map-get($colors, '#{$type}-1');
  border-right: #{$thiccness} solid map-get($colors, '#{$type}-1');
  border-bottom: #{$thiccness} solid map-get($colors, '#{$type}-1');
  border-left: #{$thiccness} solid map-get($colors, '#{$type}-2');
}

.spinner {
  display: inline-block;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &-large {
    &-primary {
      @include type(30px, 3px, 'primary')
    }

    &-secondary {
      @include type(30px, 3px, 'secondary')
    }
  }

  &-small {
    &-primary {
      @include type(19px, 2px, 'primary')
    }

    &-secondary {
      @include type(19px, 2px, 'secondary')
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

export const storeFactory = (contentFetchFn) => {
  return {
    namespaced: true,
    state: {
      loading: true,
      exception: false,
      content: null
    },
    mutations: {
      setLoading (state, loading) {
        state.loading = loading
      },
      setException (state, exception) {
        state.exception = exception
      },
      setContent (state, content) {
        state.content = content
      }
    },
    actions: {
      async loadContent ({ commit }) {
        try {
          commit('setException', false)
          const content = await contentFetchFn()

          commit('setContent', content)
        } catch {
          commit('setException', true)
        } finally {
          commit('setLoading', false)
        }
      }
    }
  }
}

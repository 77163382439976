import Icon1 from '@/assets/svg/chat_big.svg'
import Icon2 from '@/assets/svg/broadband_big.svg'
import Icon3 from '@/assets/svg/world_big.svg'

import ContentfulWrapper from '@telia/contentful-wrapper'

const getContentfulWrapperHostname = () => {
  return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_CONTENTFUL_WRAPPER_HOST : window.location.host
}

const client = new ContentfulWrapper({
  space: process.env.VUE_APP_CONTENTFUL_SPACE,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
  host: getContentfulWrapperHostname()
})

export const fetchEnglishPageContent = async () => {
  return client.getEntry('14QcCO1yoMy6TqbZiqdTsN')
}

export const fetchContactPageContent = async () => {
  return client.getEntry('1HInZuJmSDc5Aj3biZQdX0', { 'include': 2 })
}

export const fetchAboutPageContent = async () => {
  return client.getEntry('2j6bsa1qkB3Y0S5SRp6QrT')
}

export const fetchServiceProvidersContent = async () => {
  return client.getEntries({
    'content_type': 'operator',
    'order': 'fields.title'
  })
}

export const fetchIntegrityPolicyContent = async () => {
  return client.getEntry('5UdFJX8eKahZQSbm1wpQFE')
}

export const fetchFaqContent = async () => {
  return client.getEntries({
    'content_type': 'faq',
    'select': 'fields'
  })
}

export const fetchCookieConsentContent = async () => {
  return client.getEntry('5i7FNH9sVPfcmK9RuNK8KR')
}

export const fetchBroadbandComparisonContent = async () => {
  return Promise.resolve({
    heading: 'Jämför bredband',
    subheading: '– välj rätt tjänster utifrån dina behov',
    descriptionLabel: 'Allmänt om hastighet',
    description: '<p>På vissa uppkopplingar anges två värden (tex. 100/10) för hastigheten. Det första värdet är nedladdningshastighet, det andra är uppladdningshastighet.</p><p>Nedladdningshastigheten anger hur snabbt din uppkoppling kan ta emot data. En hög nedladdningshastighet innebär att du laddar ner filer, ser på streamad tv och surfar snabbare.</p><p>Uppladdningshastigheten anger med vilken hastighet du kan skicka data, vilket påverkar hur snabbt du kan skicka filer och spela online.</p>',
    infoLabel: 'Vilken hastighet passar mig?',
    broadbandSpeedDescription: [
      {
        title: '1000 Mbit/s',
        content: '<p>Med 1000 Mbit/s (1 Gbit/s) kommer hastigheten aldrig att begränsa dig.</p><p>Koppla upp flera datorer samtidigt, hämta och skicka stora filer, streama film elller spela onlinespel.</p>'
      },
      {
        title: '100 Mbit/s',
        content: '<p>För dig som skickar stora filer eller spelar online.</p><p>Flera datorer samtidigt kan utföra krävande tjänster samtidigt – perfekt för familjen som har flera datorer i hushållet. Med 100 Mbit/s, upp och ner, skickar du data lika fort som du tar emot.</p>'
      },
      {
        title: '10 Mbit/s',
        content: '<p>Sköter du bankärenden och surfar hemifrån? Ett prisvärt alternativ för dig som inte laddar ner stora filer eller spelar online.</p>'
      }
    ]
  })
}

export const fetchLandingPageContent = async () => {
  return Promise.resolve({
    hero: {
      heading: 'Bredbandswebben – tjänster för dig ansluten till Öppen Fiber från Telia',
      subHeading: 'Med vårt öppna fibernät väljer du tjänsteleverantör och beställer själv bredband, tv och telefoni.',
      buttonText: 'Se vilka tjänster du kan beställa'
    },
    serviceProviders: {
      heading: 'Tjänstelevarantörer'
    },
    icons: {
      isGreyBackground: false,
      items: [
        {
          link: '/fragor-och-svar',
          icon: Icon1,
          text: 'Vanliga frågor'
        },
        {
          link: '/jamfor',
          icon: Icon2,
          text: 'Jämför bredband'
        },
        {
          link: '/english',
          icon: Icon3,
          text: 'English'
        }
      ]
    }
  })
}

export const fetchFooterContent = async () => {
  return Promise.resolve({
    copyright: '© Telia Sverige AB 556430-0142 | Säte: Stockholm | All rights reserved'
  })
}

<template>
  <div
    class="modal"
    :class="[{'modal--fill': fillScreen}, `modal--${variant}`, `modal--${position}`]">
    <header class="modal__header">
      <ActionButton
        v-if="closable"
        class="modal__button"
        text="Stäng"
        icon="close"
        @click="$emit('close')" />

      <h3 class="modal__heading">
        {{ heading }}
      </h3>
    </header>

    <div class="modal__body">
      <slot />
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton.vue'

export default {
  components: {
    ActionButton
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    fillScreen: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      required: true,
      validator (variant) {
        return [
          'info',
          'warning',
          'input',
          'error'
        ].indexOf(variant) !== -1
      }
    },
    position: {
      type: String,
      default: 'center',
      validator (position) {
        return [
          'left',
          'center',
          'popup'
        ].indexOf(position) !== -1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

@mixin popup-positioning {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

$small-screen-offset: 26px;

.modal {
  border-left: solid 1rem;
  position: relative;
  padding: 3rem $small-screen-offset;
  background-color: $core-white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: auto;

  &--info {
    border-color: $grey-100;
  }

  &--warning {
    border-color: $warning;
  }

  &--input {
    border-color: $success;
  }

  &--error {
    border-color: $error;
  }

  &--fill,
  &--popup {
    @include popup-positioning;
    width: 100%;
    height: 100vh;
  }

  @media(min-width: $breakpoint-md) {
    width: 50%;
    padding: 3rem;

    &--popup {
      max-height: 80vh;
    }

    &--center {
      margin: 0 auto;
    }

    &--fill {
      position: static;
      transform: translate(0, 0);
      height: auto;
    }
  }

  &__button {
    position: absolute;
    top: 28px;
    right: $small-screen-offset;

    @media(min-width: $breakpoint-md) {
      right: 1rem;
    }
  }

  &__heading {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 36px;
    margin: 0;
    letter-spacing: 2px;
    line-height: 50px;

    @media(max-width: $breakpoint-sm) {
      font-size: 18px;
      line-height: 25.5px;
      letter-spacing: 0.5px;
    }
  }
}
</style>

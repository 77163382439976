import AddressSearchService from '@/services/address-search.service.js'
import NetAdminService from '@/services/net-admin.service.js'
import {
  setAddressInfo, getAddressInfo
} from '@/services/local-storage.service'

const ERROR_MESSAGE = 'Addressen kunde inte hittas. Kontrollera uppgifterna och försök igen.'

export const NATFRAGA_RESPONSE_CODES = {
  OK: 1501,
  ADDRESS_MISSING: 1510,
  TEMP_ERROR: 1513,
  TECH_ERROR: 1514,
  OTHER: -1,
  NOT_AVAILABLE: 0
}

export default {
  namespaced: true,
  state: {
    error: null,
    loadingAutocompleteInput: false,
    loadingAddresses: false,
    responseCode: null,
    cities: [],
    streets: [],
    streetNumbers: [],
    city: null,
    street: null,
    streetNumber: null,
    addresses: [],
    selectedAddress: null,
    accessType: null,
    referenceNumber: null
  },
  mutations: {
    setError (state, value) {
      state.error = value
    },
    setLoadingAutocompleteInput (state, value) {
      state.loadingAutocompleteInput = value
    },
    setLoadingAddresses (state, value) {
      state.loadingAddresses = value
    },
    setResponseCode (state, value) {
      state.responseCode = value
    },
    setCities (state, value) {
      state.cities = value
    },
    setStreets (state, value) {
      state.streets = value
    },
    setStreetNumbers (state, value) {
      state.streetNumbers = value
    },
    setCity (state, value) {
      state.city = value
    },
    setStreet (state, value) {
      state.street = value
    },
    setStreetNumber (state, value) {
      state.streetNumber = value
    },
    setAddresses (state, value) {
      state.addresses = value
    },
    setSelectedAddress (state, value) {
      setAddressInfo(value)
      state.selectedAddress = value
    },
    setAccessType (state, value) {
      state.accessType = value
    },
    setReferenceNumber (state, value) {
      state.referenceNumber = value
    }
  },
  actions: {
    async searchCities ({ commit }, city) {
      try {
        commit('setLoadingAutocompleteInput', true)
        const result = await AddressSearchService.fetchCities({
          city
        })

        commit('setCities', result)
        commit('setStreets', [])
        commit('setStreetNumbers', [])

      } catch {
        //nothing is needed
      } finally {
        commit('setLoadingAutocompleteInput', false)
      }
    },
    async searchStreets ({ commit, state }, street) {
      if (!state.city) {
        return
      }
      try {
        commit('setLoadingAutocompleteInput', true)
        const result = await AddressSearchService.fetchStreets({
          city: state.city,
          street
        })

        commit('setStreets', result)
        commit('setStreetNumbers', [])
      } catch {
        //nothing is needed
      } finally {
        commit('setLoadingAutocompleteInput', false)
      }
    },
    async searchStreetNumbers ({ commit, state }, number) {
      if (!state.city || !state.street) {
        return
      }
      try {
        commit('setLoadingAutocompleteInput', true)
        const result = await AddressSearchService.fetchStreetNumbers({
          city: state.city,
          street: state.street,
          number
        })

        commit('setStreetNumbers', result)
      } catch {
        //nothing is needed
      } finally {
        commit('setLoadingAutocompleteInput', false)
      }
    },
    async searchAddresses ({ commit }, payload) {
      try {
        commit('setLoadingAddresses', true)
        const result = await AddressSearchService.fetchAddresses(payload)
        commit('setAddresses', result.matchingAddresses)
        commit('setResponseCode', result.responseCode)
      } catch (err) {
        commit('setError', ERROR_MESSAGE)
      } finally {
        commit('setLoadingAddresses', false)
      }
    },
    async precheckOnAddress ({ commit }, payload) {
      try {
        commit('setLoadingAddresses', true)
        const result = await AddressSearchService.fetchAccessInfo(payload)
        commit('setAccessType', result.accessTypeCode)
        commit('setReferenceNumber', result.referenceNumber)
      } catch (err) {
        commit('setError', ERROR_MESSAGE)
      } finally {
        commit('setLoadingAddresses', false)
      }
    },
    async searchAddress ({ commit }, payload) {
      try {
        commit('setLoadingAddresses', true)
        const result = await NetAdminService.fetchAddressByAddressId(payload)

        commit('setSelectedAddress', result)
      } catch (err) {
        commit('setError', ERROR_MESSAGE)
      } finally {
        commit('setLoadingAddresses', false)
      }
    },
    clearResponseCode ({ commit }) {
      commit('setResponseCode', null)
    },
    clearCities ({ commit }) {
      commit('setCities', [])
    },
    clearStreets ({ commit }) {
      commit('setStreets', [])
    },
    clearStreetNumbers ({ commit }) {
      commit('setStreetNumbers', [])
    },
    cancelAutocompleteInput ({ commit }) {
      commit('setLoadingAutocompleteInput', false)
    },
    clearError ({ commit }) {
      commit('setError', null)
    },
    clearAddresses ({ commit }) {
      commit('setAddresses', [])
    },
    clearSelectedAddress ({ commit }) {
      commit('setSelectedAddress', null)
    },
    setSelectedAddress ({ commit }, address) {
      commit('setSelectedAddress', address)
    },
    setSelectedCity ({ commit }, city) {
      commit('setCity', city)
      commit('setStreet', null)
      commit('setStreets', [])
      commit('setStreetNumber', null)
      commit('setStreetNumbers', [])

    },
    setSelectedStreet ({ commit }, street) {
      commit('setStreet', street)
      commit('setStreetNumber', null)
      commit('setStreetNumbers', [])
    },
    setSelectedStreetNumber ({ commit }, streetNumber) {
      commit('setStreetNumber', streetNumber)
    }
  },
  getters: {
    noAddressesFound: ({ responseCode }) => {
      return responseCode === NATFRAGA_RESPONSE_CODES.ADDRESS_MISSING
    },
    noFiberFound: ({ responseCode, addresses }) => {
      return responseCode === NATFRAGA_RESPONSE_CODES.NOT_AVAILABLE || responseCode === NATFRAGA_RESPONSE_CODES.OK && !addresses.length
    },
    addressesFound: ({ responseCode, addresses }) => {
      return responseCode === NATFRAGA_RESPONSE_CODES.OK && addresses.length
    },
    systemError: ({ responseCode }) => {
      return responseCode === NATFRAGA_RESPONSE_CODES.TECH_ERROR || responseCode === NATFRAGA_RESPONSE_CODES.TEMP_ERROR
    },
    selectedAddress: (state) => {
      return state.selectedAddress || getAddressInfo()
    }
  }
}

import axios from 'axios'

export const createInstance = baseUrl => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: 'json'
  })
}

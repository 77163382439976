<template>
  <div class="header">
    <nav
      v-if="hideNavigation"
      class="navigation navigation-closed desktop-container">
      <div class="mobile-container navigation-top">
        <a
          class="navigation-heading"
          @click="navigateTo(routePath.closed.Root + '/' + routePath.closed.LandingPage)">Bredbandswebben</a>
      </div>
    </nav>

    <nav
      v-else
      class="navigation navigation-public desktop-container">
      <div class="mobile-container navigation-top">
        <a
          class="navigation-heading"
          @click="navigateTo(routePath.public.Root)">Bredbandswebben</a>
        <div
          v-if="!isNavigationItemsVisible"
          class="navigation-top-mobile-toggle"
          @click="openMobileMenu()">
          <img
            alt="Mobile menu opening"
            src="@/assets/svg/mobile_menu_open.svg"
            width="20">
          <div>Meny</div>
        </div>
        <div
          v-else
          class="navigation-top-mobile-toggle"
          @click="closeMobileMenu()">
          <img
            alt="Mobile menu closing"
            src="@/assets/svg/mobile_menu_close.svg"
            width="20">
          <div>Stäng meny</div>
        </div>
      </div>

      <ul
        class="navigation-items navigation-items-mobile"
        :class="mobileItemsClasses">
        <li
          v-for="(item, index) in navigationItems"
          :key="index"
          class="mobile-container"
          @click="navigateTo(item.path)">
          <div
            class="navigation-item"
            :class="{ 'navigation-item__active': $route.path === `/${item.path}` }">
            {{ item.linkText }}
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {
  RouterLinks, RoutePath
} from '@/router/router-constants'

export default {
  data () {
    return {
      navigationItems: RouterLinks,
      isNavigationItemsVisible: false,
      resizeTimer: null,
      isTransitionDisabled: false,
      routePath: RoutePath
    }
  },
  computed: {
    mobileItemsClasses () {
      return {
        'navigation-items--hidden': !this.isNavigationItemsVisible,
        'navigation-items--disable-transition': this.isTransitionDisabled
      }
    },
    hideNavigation () {
      const rootPath = this.$route.matched[0]

      return !this.$route.name || rootPath && rootPath.path === RoutePath.closed.Root
    }
  },
  created () {
    window.addEventListener('resize', this.transitionStopper)
  },
  destroyed () {
    window.removeEventListener('resize', this.transitionStopper)
  },
  methods: {
    openMobileMenu () {
      this.isNavigationItemsVisible = true
    },
    closeMobileMenu () {
      this.isNavigationItemsVisible = false
    },
    navigateTo (path) {
      this.closeMobileMenu()
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path)
      }
    },
    transitionStopper () {
      this.isTransitionDisabled = true

      clearTimeout(this.resizeTimer)

      this.resizeTimer = setTimeout(() => {
        this.isTransitionDisabled = false
      }, 400)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/main.scss";

$row-padding-lg: 26px;
$row-padding-xl: 52px;

.desktop-container {
  @media(min-width: $breakpoint-lg) {
    @include container-mixin();
  }

  transition: padding 0.5s ease-in-out;
}

.mobile-container {
  @media(max-width: $breakpoint-md-max) {
    @include container-mixin();
  }

  transition: padding 0.5s ease-in-out;
}

.navigation {
  @media(min-width: $breakpoint-lg) {
    display: flex;
    align-items: center;
    padding-top: $row-padding-xl;
    padding-bottom: $row-padding-xl;
  }

  &-heading {
    font-family: Helvetica, Arial, sans-serif;
  }

  &-top {
    a {
      text-decoration: none;
      color: $core-black;
      margin: 0;
      font-weight: bold;
      font-style: normal;
      font-size: 24px;
    }
    @media(min-width: $breakpoint-lg) {
      margin-right: 80px;
    }

    @media(max-width: $breakpoint-md-max) {
      padding-top: $row-padding-lg;
      padding-bottom: $row-padding-lg;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-mobile-toggle {
      display: none;

      @media(max-width: $breakpoint-md-max) {
        display: flex;
      }

      &:hover {
        cursor: pointer;
      }

      img {
        fill: $core-black;
        margin-right: 10px;
      }
    }
  }

  &-items {
    list-style: none;
    margin: 0;
    padding: 0;

    max-height: 1000px;
    overflow: hidden;
    opacity: 1;

    &--disable-transition {
      animation: none !important;
      transition: none !important;
    }

    @media(max-width: $breakpoint-md-max) {
      transition: all .5s ease-in-out;

      &--hidden {
        opacity: 0;
        max-height: 0;
      }

    }

    @media(min-width: $breakpoint-lg) {
      display: flex;
      flex: auto;
    }

    li {
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        div {
          margin-left: 30px;
        }

        &:before {
          position: absolute;
          top: 25%;
          height: 20px;
          width: 20px;
          content: url('../../assets/svg/world.svg');
        }
      }

      @media(max-width: $breakpoint-md-max) {
        color: $core-white;
        background-color: $core-teal-500;

        padding-top: $row-padding-lg;
        padding-bottom: $row-padding-lg;

        &:hover {
          background-color: $core-teal-600;
        }

        &:first-child {
          border-top: 1px solid;
          border-color: $core-black;
        }

        &:not(:first-child) {
          border-top: 1px solid;
          border-color: $core-white;
        }

        &:last-child {
          border-bottom: 1px solid;
          border-color: $core-white;
          background-color: $core-black;

          &:hover {
            background-color: $grey-200;
            color: $core-black;
            &:before {
              content: url('../../assets/svg/world_mobile_black.svg');
            }
          }

          div {
            margin-left: 40px;
          }

          &:before {
            top: 30%;
            height: 26px;
            width: 26px;
            content: url('../../assets/svg/world_mobile_white.svg');
          }
        }
      }

      @media(min-width: $breakpoint-lg) {
        &:not(:first-child) {
          margin-left: 30px;
        }
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }

  &-item {
    @media(min-width: $breakpoint-lg) {
      padding-top: 14px;
      padding-bottom: 14px;

      &__active {
        border-bottom: 2px solid;
        border-color: $core-black;
        padding-bottom: 12px;
      }
    }
  }
}

</style>

export const SORTING_KEY = {
  ALPHABET_A_Z: 'ALPHABET_A_Z',
  ALPHABET_Z_A: 'ALPHABET_Z_A',
  BANDWIDTH_SPEED_HIGH_LOW: 'BANDWIDTH_SPEED_HIGH_LOW',
  BANDWIDTH_SPEED_LOW_HIGH: 'BANDWIDTH_SPEED_LOW_HIGH',
  PRICE_HIGH_LOW: 'PRICE_HIGH_LOW',
  PRICE_LOW_HIGH: 'PRICE_LOW_HIGH'
}

export const PLATFORM_FILTER_KEY = {
  BREDBAND: 'bredband',
  TV: 'tv',
  TELEFONI: 'voip'
}

export const sortOptions = [
  {
    value: SORTING_KEY.ALPHABET_A_Z,
    label: 'Alfabetisk: A-Ö'
  },
  {
    value: SORTING_KEY.ALPHABET_Z_A,
    label: 'Alfabetisk: Ö-A'
  },
  {
    value: SORTING_KEY.BANDWIDTH_SPEED_HIGH_LOW,
    label: 'Hastighet: Högt till lågt'
  },
  {
    value: SORTING_KEY.BANDWIDTH_SPEED_LOW_HIGH,
    label: 'Hastighet: Lågt till högt'
  },
  {
    value: SORTING_KEY.PRICE_HIGH_LOW,
    label: 'Pris: Högt till lågt'
  },
  {
    value: SORTING_KEY.PRICE_LOW_HIGH,
    label: 'Pris: Lågt till högt'
  }
]

export const sortByBandwidthSpeedLowHigh = (a, b) => a.downloadSpeedMBits - b.downloadSpeedMBits || a.uploadSpeedMBits - b.uploadSpeedMBits || a.price - b.price
export const sortByBandwidthSpeedHighLow = (a, b) => b.downloadSpeedMBits - a.downloadSpeedMBits || b.uploadSpeedMBits - a.uploadSpeedMBits || a.price - b.price

export const sortByPriceLowHigh = (a, b) => a.price - b.price
export const sortByPriceHighLow = (a, b) => b.price - a.price

export const sortAlphabetAZ = (a, b) => a.serviceProvider.name.localeCompare(b.serviceProvider.name) || a.friendlyName.localeCompare(b.friendlyName)
export const sortAlphabetZA = (a, b) => b.serviceProvider.name.localeCompare(a.serviceProvider.name) || b.friendlyName.localeCompare(a.friendlyName)

export const sortingMap = {
  [SORTING_KEY.BANDWIDTH_SPEED_HIGH_LOW]: sortByBandwidthSpeedHighLow,
  [SORTING_KEY.BANDWIDTH_SPEED_LOW_HIGH]: sortByBandwidthSpeedLowHigh,
  [SORTING_KEY.PRICE_LOW_HIGH]: sortByPriceLowHigh,
  [SORTING_KEY.PRICE_HIGH_LOW]: sortByPriceHighLow,
  [SORTING_KEY.ALPHABET_A_Z]: sortAlphabetAZ,
  [SORTING_KEY.ALPHABET_Z_A]: sortAlphabetZA
}

import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

import AddressSearch from './address-search.store'
import AvailableOffers from './available-offers.store'
import OrderSubmit from './order-submit.store'
import CookieConsent from './cookie-consent.store'
import BBWFlow from './bbw-flow.store'

import { storeFactory } from './content.store'
import {
  fetchBroadbandComparisonContent,
  fetchFooterContent,
  fetchFaqContent,
  fetchEnglishPageContent,
  fetchContactPageContent,
  fetchAboutPageContent,
  fetchServiceProvidersContent,
  fetchIntegrityPolicyContent,
  fetchLandingPageContent,
  fetchCookieConsentContent
} from '@/services/content.service'

const Footer = storeFactory(fetchFooterContent)
const BroadbandComparison = storeFactory(fetchBroadbandComparisonContent)
const EnglishPage = storeFactory(fetchEnglishPageContent)
const ContactPage = storeFactory(fetchContactPageContent)
const AboutPage = storeFactory(fetchAboutPageContent)
const ServiceProviders = storeFactory(fetchServiceProvidersContent)
const IntegrityPolicyPage = storeFactory(fetchIntegrityPolicyContent)
const LandingPage = storeFactory(fetchLandingPageContent)
const Faq = storeFactory(fetchFaqContent)
const CookieConsentContent = storeFactory(fetchCookieConsentContent)

export default new Vuex.Store({
  modules: {
    AddressSearch,
    AvailableOffers,
    BBWFlow,
    OrderSubmit,
    LandingPage,
    ContactPage,
    ServiceProviders,
    AboutPage,
    EnglishPage,
    IntegrityPolicyPage,
    Footer,
    BroadbandComparison,
    Faq,
    CookieConsentContent,
    CookieConsent
  }
})

import { createInstance } from './axios.api'

export const autocompleteApi = createInstance(process.env.VUE_APP_ADDRESS_AUTOCOMPLETE_URL)
export const precheckApi = createInstance(process.env.VUE_APP_PRECHECK_URL)

const addressMapper = (result) => result.map(item => {
  return {
    'label': item,
    'value': item
  }
})

const fetchCities = async (payload) => {
  const { data } = await autocompleteApi.post('/suggest/city', payload)
  return addressMapper(data)
}

const fetchStreets = async (payload) => {
  const { data } = await autocompleteApi.post('/suggest/street', payload)
  return addressMapper(data)
}

const fetchStreetNumbers = async (payload) => {
  const { data } = await autocompleteApi.post('/suggest/number', payload)
  return addressMapper(data)
}

const fetchAddresses = async (payload) => {
  const { data } = await precheckApi.post('/v1/evaluate', payload)
  return data
}

const fetchAccessInfo = async (payload) => {
  const { data } = await precheckApi.post('/v1/precheck', payload)
  return data
}

export default {
  fetchCities,
  fetchStreets,
  fetchStreetNumbers,
  fetchAddresses,
  fetchAccessInfo
}

export const RoutePath = {
  public: {
    Root: '/',
    LandingPage: '',
    ServiceProviders: 'tjanster',
    FAQ: 'fragor-och-svar',
    AboutUs: 'om-oss',
    Contact: 'kontakt',
    English: 'english',
    BroadbandComparison: 'jamfor',
    AvailableOffers: 'tillgangliga-erbjudanden',
    AddressSearch: 'sokadress',
    IntegrityPolicy: 'din-integritet'
  },
  closed: {
    Root: '/selfprovisioning',
    LandingPage: 'cw2',
    LandingPageOld: 'cw',
    AvailableOffers: 'tillgangliga-erbjudanden',
    Offer: 'erbjudande',
    ThankYou: 'bestallning-genomford',
    IntegrityPolicy: 'din-integritet'
  }
}

export const RouteName = {
  public: {
    LandingPage: 'LandingPagePublic',
    ServiceProviders: 'ServiceProviders',
    FAQ: 'FAQ',
    AboutUs: 'AboutUs',
    Contact: 'Contact',
    English: 'English',
    BroadbandComparison: 'BroadbandComparison',
    AvailableOffers: 'AvailableOffersPublic',
    IntegrityPolicy: 'IntegrityPolicy',
    AddressSearch: 'AddressSearch'
  },
  closed: {
    LandingPage: 'LandingPageClosed',
    LandingPageOld: 'LandingPageClosedOld',
    AvailableOffers: 'AvailableOffersClosed',
    Offer: 'Offer',
    ThankYou: 'ThankYou',
    IntegrityPolicy: 'IntegrityPolicy'
  }
}

export const RouterLinks = [
  {
    path: RoutePath.public.ServiceProviders,
    linkText: 'Tjänster och leverantörer'
  },
  {
    path: RoutePath.public.FAQ,
    linkText: 'Frågor och svar'
  },
  {
    path: RoutePath.public.AboutUs,
    linkText: 'Om oss'
  },
  {
    path: RoutePath.public.Contact,
    linkText: 'Kontakt'
  },
  {
    path: RoutePath.public.English,
    linkText: 'English'
  }
]

<template>
  <main>
    <Header />

    <div id="main-content">
      <router-view />
    </div>

    <Footer />

    <CookieConsentPopup />
    <CookiePolicyModalWrapper />
    <CookieConsentModalWrapper />
  </main>
</template>
<script>
import Header from '@/components/header/Header'
import Footer from '@/components/Footer'
import CookieConsentModalWrapper from '@/components/cookie-consent/CookieConsentModalWrapper'
import CookiePolicyModalWrapper from '@/components/cookie-consent/CookiePolicyModalWrapper'
import CookieConsentPopup from '@/components/cookie-consent/CookieConsentBanner'

export default {
  components: {
    CookieConsentPopup,
    CookiePolicyModalWrapper,
    CookieConsentModalWrapper,
    Header,
    Footer
  }
}
</script>

<style lang="scss">
html {
  * { box-sizing: border-box; }
  font-family: Roboto, Arial, sans-serif;
}

body {
  margin: 0;
  overflow: auto;
}

input {
  margin: 0;
  font-size: 16px;
  font-family: "Roboto", Arial, sans-serif;
}

main {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

#main-content {
  flex-grow: 1;
}

.unscrollable-body {
  overflow: hidden;
}
</style>

import {
  NET_ADMIN_PARAMS, OFFER_LIST, ADDRESS_INFO, SUBSCRIPTION_LIST
} from '@/constants/local-storage-keys'

export const getNetAdminParams = () => {
  const params = localStorage.getItem(NET_ADMIN_PARAMS)
  return JSON.parse(params) || {}
}

export const setNetAdminParams = (params) => localStorage.setItem(NET_ADMIN_PARAMS, JSON.stringify(params))

export const getOfferList = () => {
  const offers = localStorage.getItem(OFFER_LIST)
  return JSON.parse(offers) || []
}

export const setOfferList = (offers) => localStorage.setItem(OFFER_LIST, JSON.stringify(offers))

export const getSubscriptionsList = () => {
  const subscriptions = localStorage.getItem(SUBSCRIPTION_LIST)
  return JSON.parse(subscriptions) || []
}

export const setSubscriptionList = (subscriptions) => localStorage.setItem(SUBSCRIPTION_LIST, JSON.stringify(subscriptions))

export const getAddressInfo = () => {
  const offers = localStorage.getItem(ADDRESS_INFO)
  return JSON.parse(offers) || {}
}

export const setAddressInfo = (address) => localStorage.setItem(ADDRESS_INFO, JSON.stringify(address))

export const clearOffers = () => {
  localStorage.removeItem(OFFER_LIST)
}

export const clearSubscriptions = () => {
  localStorage.removeItem(SUBSCRIPTION_LIST)
}

<template>
  <a @click="openPolicyModal">{{ content }}</a>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CookiePolicyLink',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions('CookieConsent', ['openPolicyModal'])
  }
}
</script>

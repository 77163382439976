<template>
  <button
    :class="[type, expanded ? 'expanded' : '']"
    :disabled="disabled"
    @click="$emit('click')">
    <span>{{ label }}</span>
    <Spinner
      v-if="loading"
      class="button-spinner"
      :type="type === 'primary' ? 'secondary' : 'primary'"
      :size="'small'" />
  </button>
</template>

<script>
import Spinner from '@/components/Spinner'

export default {
  components: {
    Spinner
  },
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'primary'
    },
    expanded: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 38px;
  border-radius: 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  &:hover:disabled {
    cursor: default;
  }

  & > .button-spinner {
    margin-left: 8px;
  }

  @media(max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.expanded {
  width: 100%;
}

.primary {
  border: unset;
  color: $core-white;
  background: $core-teal-500;

  &:disabled {
    background: $core-teal-400;
  }

  &:hover:enabled {
    background: $core-teal-600;
  }
}

.secondary {
  border: 2px solid $core-teal-500;
  color: $core-teal-500;
  background: $core-white;

  &:disabled {
    background: $grey-300;
  }

  &:hover:enabled {
    background: $core-teal-100;
    border: 2px solid $core-teal-800;
    color: $core-teal-800;
  }
}
</style>

import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router/router'
import Vuelidate from 'vuelidate'
import CookiePolicyLink from './components/cookie-consent/CookiePolicyLink'
require('@/assets/scss/main.scss')

Vue.component('cookie-policy-link', CookiePolicyLink)

Vue.use(Vuelidate)

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#bbw')

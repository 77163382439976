import { getNetAdminParams } from '@/services/local-storage.service'

export default {
  namespaced: true,
  getters: {
    addressId: () => {
      return getNetAdminParams().addressId
    },
    companyId: () => {
      return getNetAdminParams().companyId
    },
    punktId: () => {
      return getNetAdminParams().punktId
    },
    koId: () => {
      return getNetAdminParams().koId
    },
    estateOwner: () => {
      return getNetAdminParams().estateOwner
    },
    checkSum: () => {
      return getNetAdminParams().checkSum
    }
  }
}

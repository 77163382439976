import {
  RouteName,
  RoutePath
} from './router-constants'
import {
  getNetAdminParams,
  setNetAdminParams
} from '@/services/local-storage.service'

const guardNavigationFrom = (from, nextFunc, validRouteNamesFrom, fallbackRouteName) => {
  if (validRouteNamesFrom.includes(from.name)) {
    nextFunc()
  } else {
    nextFunc({ name: fallbackRouteName })
  }
}

export const routes = [
  {
    path: RoutePath.public.Root,
    component: /* istanbul ignore next */ () => import(/* webpackChunkName: "public-pass-through" */ '@/views/common/MainView.vue'),
    redirect: { name: RouteName.public.LandingPage },
    children: [
      {
        path: RoutePath.public.LandingPage,
        name: RouteName.public.LandingPage,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "landing-page-view" */ '@/views/public/LandingPage.vue')
      },
      {
        path: RoutePath.public.ServiceProviders,
        name: RouteName.public.ServiceProviders,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "service-providers-view" */ '@/views/public/ServiceProviders.vue')
      },
      {
        path: RoutePath.public.FAQ,
        name: RouteName.public.FAQ,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "faq-view" */ '@/views/public/FaqPage.vue')
      },
      {
        path: RoutePath.public.AboutUs,
        name: RouteName.public.AboutUs,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "about-view" */ '@/views/public/AboutPage.vue')
      },
      {
        path: RoutePath.public.Contact,
        name: RouteName.public.Contact,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "contact-view" */ '@/views/public/ContactPage.vue')
      },
      {
        path: RoutePath.public.English,
        name: RouteName.public.English,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "english-view" */ '@/views/public/EnglishPage.vue')
      },
      {
        path: RoutePath.public.BroadbandComparison,
        name: RouteName.public.BroadbandComparison,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "broadband-comparison" */ '@/views/public/BroadbandComparison.vue')
      },
      {
        path: RoutePath.public.AvailableOffers,
        name: RouteName.public.AvailableOffers,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "available-offers" */ '@/views/common/AvailableOffers.vue')
      },
      {
        path: RoutePath.public.IntegrityPolicy,
        name: RouteName.public.IntegrityPolicy,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "integrity-policy-view" */ '@/views/common/IntegrityPolicyPage.vue')
      },
      {
        path: RoutePath.public.AddressSearch,
        name: RouteName.public.AddressSearch,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "address-search-view" */ '@/views/public/AddressSearchPage.vue')
      }
    ]
  },
  {
    path: RoutePath.closed.Root,
    component: /* istanbul ignore next */ () => import(/* webpackChunkName: "closed-pass-through" */ '@/views/common/MainView.vue'),
    redirect: { name: RouteName.closed.LandingPage },
    children: [
      {
        path: RoutePath.closed.LandingPageOld,
        name: RouteName.closed.LandingPageOld,
        redirect: { name: RouteName.closed.LandingPage }
      },
      {
        path: RoutePath.closed.LandingPage,
        name: RouteName.closed.LandingPage,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "landing-page-closed-view" */ '@/views/closed/LandingPageClosed.vue'),
        beforeEnter: (to, _, next) => {
          const netAdminParams = getNetAdminParams()

          setNetAdminParams({
            addressId: to.query.adrid || netAdminParams.addressId,
            companyId: to.query.cid || netAdminParams.companyId,
            punktId: to.query.ai || netAdminParams.punktId,
            koId: to.query.ac3 || netAdminParams.koId,
            estateOwner: to.query.eo || netAdminParams.estateOwner,
            checkSum: to.query.checksum || netAdminParams.checkSum
          })
          next()
        }
      },
      {
        path: RoutePath.closed.AvailableOffers,
        name: RouteName.closed.AvailableOffers,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "available-offers-closed-view" */ '@/views/common/AvailableOffers.vue'),
        props: { isClosed: true }
      },
      {
        path: RoutePath.closed.Offer,
        name: RouteName.closed.Offer,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "offer" */ '@/views/closed/OfferPage.vue'),
        beforeEnter: (_, from, next) => {
          guardNavigationFrom(from, next, [RouteName.closed.AvailableOffers], RouteName.closed.LandingPage)
        }
      },
      {
        path: RoutePath.closed.ThankYou,
        name: RouteName.closed.ThankYou,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "offer-completed" */ '@/views/closed/ThankYouPage.vue'),
        beforeEnter: (_, from, next) => {
          guardNavigationFrom(from, next, [RouteName.closed.Offer], RouteName.closed.LandingPage)
        }
      },
      {
        path: RoutePath.closed.IntegrityPolicy,
        name: RouteName.closed.IntegrityPolicy,
        component: /* istanbul ignore next */ () => import(/* webpackChunkName: "integrity-policy-view-closed" */ '@/views/common/IntegrityPolicyPage.vue')
      }
    ]
  }
]

import { createInstance } from './axios.api'

export const baseApi = createInstance(process.env.VUE_APP_BASE_URL)
const FETCH_OFFERS_ENDPOINT = '/combinations'
const FETCH_SUBSCRIPTIONS_ENDPOINT = '/subscriptions'
const FETCH_ADDRESS_ENDPOINT = '/address'
const ORDER_ENDPOINT = '/order'

const fetchOffersByAddressId = async (payload) => {
  const { data } = await baseApi.get(FETCH_OFFERS_ENDPOINT, { params: payload })
  return data
}

const fetchOffersByPunktId = async (payload) => {
  const { data } = await baseApi.get(FETCH_OFFERS_ENDPOINT, { params: {
    punktId: payload.punktId,
    isCorporate: payload.isCorporate
  } })
  return data
}

const fetchSubscriptions = async (payload) => {
  const { data } = await baseApi.get(FETCH_SUBSCRIPTIONS_ENDPOINT, { params: payload })
  return data
}

const fetchAddressByAddressId = async (payload) => {
  const { data } = await baseApi.get(FETCH_ADDRESS_ENDPOINT, { params: payload })
  return {
    city: data.city,
    streetName: data.street,
    streetNumber: data.streetNumber,
    apartmentNumber: data.apartmentNumber
  }
}

const submitOrder = async (payload) => {
  const { data } = await baseApi.post(ORDER_ENDPOINT, payload)
  return data
}

export default {
  fetchOffersByAddressId,
  fetchOffersByPunktId,
  fetchSubscriptions,
  fetchAddressByAddressId,
  submitOrder
}

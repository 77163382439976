import NetAdminService from '@/services/net-admin.service.js'

export default {
  namespaced: true,
  state: {
    exception: false,
    isPending: false
  },
  mutations: {
    setException (state, value) {
      state.exception = value
    },
    setIsPending (state, value) {
      state.isPending = value
    }
  },
  actions: {
    async submitOrder ({ commit }, payload) {
      try {
        commit('setException', false)
        commit('setIsPending', true)
        await NetAdminService.submitOrder(payload)

      } catch (err) {
        commit('setException', true)
      } finally {
        commit('setIsPending', false)
      }
    }
  }
}

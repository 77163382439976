<template>
  <button
    class="action-button"
    :class="{'action-button--active': active}"
    @click="$emit('click')">
    <img
      v-if="icon === 'close'"
      class="action-button__icon"
      alt="Close button icon"
      src="@/assets/svg/close.svg">

    <img
      v-if="icon === 'filter'"
      class="action-button__icon"
      alt="Filter button icon"
      src="@/assets/svg/filter.svg">

    <span class="action-button__text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true,
      validator (iconName) {
        return [
          'close',
          'filter'
        ].indexOf(iconName) !== -1
      }
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.action-button {
  appearance: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: $core-teal-500;
  font: 400 1rem Roboto;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;

  &--active {
    font-weight: 700;
  }

  &__icon {
    width: 15px;
    margin-right: 0.5rem;
  }
}

</style>

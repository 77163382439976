<template>
  <div
    v-if="!loading && !exception && modalOpen"
    class="modal-overlay">
    <CookieConsentModal
      :consent-level="levelSelected"
      :title="content.fields.modalTitle"
      :text="content.fields.modalText"
      :consent-title1="content.fields.modalConsentTitle1"
      :consent-title2="content.fields.modalConsentTitle2"
      :consent-title3="content.fields.modalConsentTitle3"
      :consent-text1="content.fields.modalConsentText1"
      :consent-text2="content.fields.modalConsentText2"
      :consent-text3="content.fields.modalConsentText3"
      @update:consentLevel="updateConsentLevel($event)"
      @close="closeModal()" />
  </div>
</template>

<script>
import CookieConsentModal from '@/components/cookie-consent/CookieConsentModal'
import {
  mapActions, mapState
} from 'vuex'

export default {
  name: 'CookieConsentModalWrapper',
  components: { CookieConsentModal },
  computed: {
    ...mapState('CookieConsentContent', [
      'loading',
      'exception',
      'content'
    ]),
    ...mapState('CookieConsent', [
      'modalOpen',
      'levelSelected'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('CookieConsentContent', ['loadContent']),
    ...mapActions('CookieConsent', [
      'closeModal',
      'updateLevelSelected'
    ]),
    updateConsentLevel (value) {
      this.updateLevelSelected(value)
      this.closeModal()
    }
  }
}
</script>

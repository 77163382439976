<template>
  <footer>
    <div class="container">
      <section class="upper">
        <h2 id="title">
          Bredbandswebben
        </h2>
        <a
          id="cookies"
          href="#"
          @click="openModal">Cookieinställningar</a>
        <a
          id="integrity"
          :href="integrityPolicyPage">Din integritet</a>
      </section>
    </div>

    <div class="container">
      <section class="lower">
        <span v-if="loading">Loading ...</span>
        <span
          v-else
          id="copyright-notice">{{ content.copyright }}</span>
      </section>
    </div>
  </footer>
</template>

<script>
import {
  mapActions, mapState
} from 'vuex'
import { RoutePath } from '@/router/router-constants'

export default {
  data () {
    return {
      integrityPolicyPage: RoutePath.public.IntegrityPolicy
    }
  },
  computed: {
    ...mapState('Footer', [
      'loading',
      'exception',
      'content'
    ])
  },
  created () {
    this.loadContent()
  },
  methods: {
    ...mapActions('Footer', ['loadContent']),
    ...mapActions('CookieConsent', ['openModal'])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

footer {
  color: $core-white;
  background: $core-black;

  .upper {
    padding: 60px 0;

    h2 {
      display: inline-block;
      font-size: 24px;
      margin: 60px 0;
    }

    a {
      color: $core-white;
      margin: 30px;
    }

    @media(max-width: $breakpoint-lg) {
      padding: 64px 0;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 18px;
      }

      a,
      h2 {
        margin: 16px 0;
      }
    }
  }

  .lower {
    background: $core-black;
    padding: 50px 0;
    display: flex;

    @media(max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: center;

      .separator {
        display: none;
      }
    }
  }
}
</style>

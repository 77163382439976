<template>
  <div
    v-if="bannerOpen"
    class="cookie-consent-banner-wrapper">
    <div class="cookie-consent-banner container">
      <div class="row">
        <div class="col-12">
          <h2>Vi använder cookies</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <p>Vi använder cookies och andra liknande tekniker på våra webbplatser. Du kan själv kontrollera vilka cookies som får placeras i din webbläsare och du kan ändra dina inställningar när som helst. Läs mer i vår cookiepolicy och gör sen dina val. Ditt godkännande sparas i ett år.</p>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <div class="cookie-consent-banner-buttons">
            <MainButton
              label="Jag godkänner cookies"
              class="cookie-consent-banner-accept-cookies"
              @click="acceptCookies" />
            <a
              href="#"
              @click="openModal()">Cookieinställningar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/MainButton'

import {
  mapActions, mapState
} from 'vuex'
export default {
  name: 'CookieConsentPopup',
  components: { MainButton },
  computed: {
    ...mapState('CookieConsent', [
      'bannerOpen',
      'levelSelected'
    ])
  },
  created () {
    this.preloadCookies()
  },
  methods: {
    ...mapActions('CookieConsent', [
      'updateLevelSelected',
      'closeBanner',
      'openModal',
      'preloadCookies'
    ]),
    acceptCookies () {
      this.updateLevelSelected(this.levelSelected)
      this.closeBanner()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.cookie-consent-banner-wrapper {
  position:fixed;
  top:auto;
  right:auto;
  left:auto;
  bottom:0;
  width: 100%;

  background-color: $core-white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-left: $success solid 1rem;
}

.cookie-consent-banner {
  margin-bottom: 45px;

  &-buttons {
    display: flex;
    flex-direction: column;
  }

  &-accept-cookies {
    margin-bottom: 40px;
  }
}
</style>

<template>
  <div class="contentful-rich-text-container">
    <RichTextRenderer
      :document="content"
      :node-renderers="renderNodes()" />
  </div>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import {
  BLOCKS, INLINES
} from '@contentful/rich-text-types'

export default {
  components: {
    RichTextRenderer
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  methods: {
    renderNodes () {
      const nodeContentWithNewlineBr = (node, key) => node.content.map(childNode => {
        if (childNode.nodeType === 'text') {
          const splittedValue = childNode.value.split('\n')
          return splittedValue.reduce((aggregate, v, i) => [
            ...aggregate,
            {
              ...childNode,
              value: v
            },
            {
              nodeType: 'break',
              key: `${key}-br-${i}`
            }
          ]
          , []).slice(0, -1)
        }

        return childNode
      })
      return {
        break: (_node, key, h) => h('br', key, {}),
        [BLOCKS.PARAGRAPH]: (node, key, h, next) => {
          return h('p', {
            key: key,
            class: 'paragraph'
          }, next([].concat.apply([], nodeContentWithNewlineBr(node, key)), key, h, next))
        },
        [BLOCKS.HEADING_1]: (node, key, h, next) => h('h1', {
          key: key,
          class: 'h1'
        }, next([].concat.apply([], nodeContentWithNewlineBr(node, key)), key, h, next)),
        [BLOCKS.HEADING_2]: (node, key, h, next) => h('h2', {
          key: key,
          class: 'h2'
        }, next([].concat.apply([], nodeContentWithNewlineBr(node, key)), key, h, next)),
        [BLOCKS.HEADING_3]: (node, key, h, next) => h('h3', {
          key: key,
          class: 'h3'
        }, next([].concat.apply([], nodeContentWithNewlineBr(node, key)), key, h, next)),
        [BLOCKS.HEADING_4]: (node, key, h, next) => h('h4', {
          key: key,
          class: 'h4'
        }, next([].concat.apply([], nodeContentWithNewlineBr(node, key)), key, h, next)),
        [INLINES.ASSET_HYPERLINK]: node => {
          const content = node.content[0].value
          const url = node.data.target.fields.file.url
          if (url && content) {
            return <a target="_blank" href={url}>{content}</a>
          }
          return null
        },
        [INLINES.HYPERLINK]: (node, key, h) => {
          const uri = node.data.uri
          const content = node.content[0].value

          if (uri && content) {
            //In order to place Vue component, Link target in contenful should start with vue.${some-component}
            const vueCompId = 'vue.'

            if (uri.startsWith(vueCompId)) {
              const customComponent = uri.replace(vueCompId, '')

              //mounting a custom vue component with props
              return h(customComponent, {
                key: key,
                props: { content: content }
              })
            }
            //else returns simple anchor link
            return <a target="_blank" href={uri}>{content}</a>
          }
          return null
        }
      }
    }
  }
}
</script>

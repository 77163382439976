import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

import { routes } from './routes'

const scrollBehavior = (to, from, savedPosition) => {
  if (to.hash) {
    return { selector: to.hash }
  }

  /* eslint-disable */
  return savedPosition
    ? { ...savedPosition, behavior: 'smooth' }
    : { x: 0, y: 0 }
  /* eslint-enable */
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes
})

export default router

<template>
  <Modal
    :heading="title"
    :closable="true"
    variant="input"
    position="popup"
    class="cookie-consent"
    @close="$emit('close')">
    <div class="cookie-consent__text">
      <RichTextContainer :content="text" />
    </div>

    <template v-if="consentTitle1 && consentText1">
      <Checkbox
        id="one"
        v-model="consentLevelsArrayProxy"
        class="cookie-consent__selection-checkbox"
        :checked-value="1"
        disabled
        :label="consentTitle1" />
      <p class="cookie-consent__selection-text">
        <RichTextContainer :content="consentText1" />
      </p>
    </template>

    <template v-if="consentTitle2 && consentText2">
      <Checkbox
        id="two"
        v-model="consentLevelsArrayProxy"
        class="cookie-consent__selection-checkbox"
        :checked-value="2"
        :disabled="consentLevelsArrayProxy.includes(3)"
        :label="consentTitle2" />
      <p class="cookie-consent__selection-text">
        <RichTextContainer :content="consentText2" />
      </p>
    </template>

    <template v-if="consentTitle3 && consentText3">
      <Checkbox
        id="three"
        v-model="consentLevelsArrayProxy"
        class="cookie-consent__selection-checkbox"
        :checked-value="3"
        :label="consentTitle3" />
      <p class="cookie-consent__selection-text">
        <RichTextContainer :content="consentText3" />
      </p>
    </template>

    <MainButton
      v-if="showSaveButton"
      class="cookie-consent__submit"
      label="Spara och godkänn"
      @click="saveConsentLevel" />
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal'
import Checkbox from '@/components/form-components/Checkbox'
import MainButton from '@/components/MainButton'
import RichTextContainer from '@/components/rich-text-container/RichTextContainer'

export default {
  name: 'CookieConsentModal',
  components: {
    MainButton,
    Checkbox,
    Modal,
    RichTextContainer
  },
  props: {
    consentLevel: {
      type: Number,
      default: 3
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: Object,
      required: true
    },
    consentTitle1: {
      type: String,
      default: null
    },
    consentText1: {
      type: Object,
      default: null
    },
    consentTitle2: {
      type: String,
      default: ''
    },
    consentText2: {
      type: Object,
      default: null
    },
    consentTitle3: {
      type: String,
      default: ''
    },
    consentText3: {
      type: Object,
      default: null
    },
    showSaveButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      consentLevelsArray: []
    }
  },
  computed: {
    consentLevelsArrayProxy: {
      get () {
        return this.consentLevelsArray
      },
      set (newValues) {
        if (newValues.length) {
          this.consentLevelsArray = this.getSequenceUpToMaxValue(newValues)
        } else {
          const necessaryCookieCheckboxId = 1
          this.consentLevelsArray = [necessaryCookieCheckboxId]
        }
      }
    }
  },
  mounted () {
    this.consentLevelsArray = this.getSequenceUpToMaxValue([this.consentLevel])
  },
  methods: {
    saveConsentLevel () {
      this.$emit('update:consentLevel', this.consentLevelsArray.length)
    },
    // auto-select previous levels if higher one is selected
    getSequenceUpToMaxValue (values) {
      const maxValue = Math.max(...values)

      const result = []
      for (let i = 1; i <= maxValue; i++) {
        result.push(i)
      }

      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-consent {
  &__text {
    margin-bottom: 30px;
  }

  &__selection-text {
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
</style>
